import fetchAPI from '../utils/fetch-api'
import { FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_FAILURE } from '../constants/actionTypes'

const BASE_URI = `${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}`

export const fetchUser = function (userId) {
	return async dispatch => {
		await fetchAPI(
			{
				url: `${BASE_URI}/users/id/${userId}`,
				types: [FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_FAILURE],
			},
			dispatch
		)
	}
}
