import React from 'react'
import { Spinner as BootstrapSpinner } from 'reactstrap'

const Spinner = () => {
	return (
		<div style={{ textAlign: 'center', color: '#8345f8' }}>
			<BootstrapSpinner animation='grow' role='status' />
		</div>
	)
}

export default Spinner
