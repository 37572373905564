import React, { useCallback } from 'react'
import { ListGroup } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import HashtagItem from './HashtagItem'
import Spinner from '../Spinner'
import { sortHashtags, updateHashtag } from '../../actions/hashtagActions'
import { LOADING_STATE_LOADING } from '../../constants/actionTypes'

import './index.scss'

const HashtagList = () => {
	const dispatch = useDispatch()
	const hashtags = useSelector(state => state.hashtags.hashtags)
	const loadingState = useSelector(state => state.hashtags.listState)
	const moveTag = useCallback(
		(dragIndex, hoverIndex) => {
			dispatch(sortHashtags(dragIndex, hoverIndex))
		},
		[dispatch]
	)
	const savePositions = useCallback(
		item => {
			dispatch(updateHashtag(item.id, { position: item.index }))
		},
		[dispatch]
	)

	return (
		<DndProvider backend={HTML5Backend}>
			<ListGroup>
				{loadingState == LOADING_STATE_LOADING ? (
					<Spinner />
				) : (
					hashtags.map((tag, index) => (
						<HashtagItem
							moveTag={moveTag}
							savePositions={savePositions}
							updateHashtag={updateHashtag}
							index={index}
							key={tag.id}
							tag={tag}
						/>
					))
				)}
			</ListGroup>
		</DndProvider>
	)
}

export default HashtagList
