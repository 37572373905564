import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Container, Row } from 'reactstrap'
import { navigate } from 'gatsby'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Spinner from '../../../components/Spinner'
import HashtagList from '../../../components/Hashtags/HashtagList'
import { fetchUser } from '../../../actions/userActions'
import { fetchHashtags } from '../../../actions/hashtagActions'

const ManageHashTags = () => {
	const dispatch = useDispatch()
	const isLoading = useSelector(state => state.users.isLoading)
	const user = useSelector(state => state.users.user)

	useEffect(() => {
		const userId = window.localStorage.getItem('userId')
		dispatch(fetchUser(userId))
	}, [dispatch])

	useEffect(() => {
		if (user) {
			if (user.is_moderator) {
				dispatch(fetchHashtags())
			} else {
				navigate('/')
			}
		}
	}, [user, dispatch])

	return (
		<Layout>
			<SEO title='Moderation - Manage Hashtags' />
			<Container>
				{isLoading ? (
					<Row className='justify-content-center hashtag-no-results px-4 pb-3'>
						<Spinner />
					</Row>
				) : (
					<HashtagList />
				)}
			</Container>
		</Layout>
	)
}

export default ManageHashTags
