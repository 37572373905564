import React, { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { MdStar, MdStarBorder, MdModeEdit } from 'react-icons/md'
import { useDrag, useDrop } from 'react-dnd'
import { Badge, Button, ListGroupItemHeading, ListGroupItemText } from 'reactstrap'

import { updateHashtag } from '../../actions/hashtagActions'
import { LOADING_STATE_LOADING } from '../../constants/actionTypes'
import { openModalDialog } from '../../actions/layoutActions'

const HashtagItem = ({ tag, index, moveTag, savePositions }) => {
	const dispatch = useDispatch()
	const ref = useRef(null)
	const [isLoading, setIsLoading] = useState(false)
	const updateState = useSelector(state => state.hashtags.updateState)

	const [, drop] = useDrop({
		accept: 'item',
		drop(item) {
			if (!ref.current) {
				return
			}
			savePositions(item)
		},
		hover(item, monitor) {
			if (!ref.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = index
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return
			}
			// Determine rectangle on screen
			const hoverBoundingRect = ref.current.getBoundingClientRect()
			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			// Determine mouse position
			const clientOffset = monitor.getClientOffset()
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}
			moveTag(dragIndex, hoverIndex)
			item.index = hoverIndex
		},
	})
	const [{ isDragging }, drag] = useDrag({
		item: { type: 'item', id: tag.id, index },
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	})
	const opacity = isDragging ? 0 : 1
	drag(drop(ref))

	const StarComponent = tag.featured ? MdStar : MdStarBorder

	useEffect(() => {
		if (isLoading && updateState != LOADING_STATE_LOADING) {
			setIsLoading(false)
		}
	}, [isLoading, updateState])

	const toggleFeatured = () => {
		setIsLoading(true)
		dispatch(updateHashtag(tag.id, { featured: !tag.featured }))
	}

	const handleEditPost = () => {
		dispatch(openModalDialog('EditHashtag', { title: 'Edit Hashtag Description', hashtagId: tag.id }))
	}

	return (
		<li ref={ref} style={{ opacity }} className='list-group-item d-flex justify-content-between align-items-center'>
			<div>
				<ListGroupItemHeading>
					{tag.hashtag}{' '}
					<Badge pill>
						{tag.cardcount} card{tag.cardcount != 1 && 's'}
					</Badge>
				</ListGroupItemHeading>
				<ListGroupItemText>{tag.desc} </ListGroupItemText>
			</div>
			<div className='action-buttons'>
				<Button
					size='sm'
					outline
					color='warning'
					onClick={toggleFeatured}
					disabled={isLoading}
					title={tag.featured ? 'Unfeature' : 'Feature'}
				>
					<StarComponent />
				</Button>
				<Button type='button' size='sm' outline color='primary' onClick={handleEditPost} className='tag-edit-button'>
					<MdModeEdit />
				</Button>
			</div>
		</li>
	)
}

HashtagItem.propTypes = {
	tag: PropTypes.object,
	moveTag: PropTypes.func,
	savePositions: PropTypes.func,
	index: PropTypes.number,
}

export default HashtagItem
